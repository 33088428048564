import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import style from './style.module.less'

function WorkLink({ post }) {
	return (
		<div className={style.item}>
			<Link to={post.frontmatter.path} style={{ textDecoration: 'none' }}>
				{
					// 	post.frontmatter.cover === null ? (
					// 	console.log(post.frontmatter.graduate)
					// ) : (
					// 	<Img
					// 		className={style.wrapper}
					// 		fluid={post.frontmatter.cover.childImageSharp.fluid}
					// 	/>
					// )
				}
				<Img
					className={style.wrapper}
					fluid={post.frontmatter.cover.childImageSharp.fluid}
				/>
				<div className={style.container}>
					<div className={style.name}>{post.frontmatter.title}</div>
					<div className={style.spec}>
						{post.frontmatter.graduate}
					</div>
				</div>
			</Link>
		</div>
	)
}

WorkLink.propTypes = {
	post: PropTypes.shape({
		id: PropTypes.string.isRequired,
		excerpt: PropTypes.string.isRequired,
		frontmatter: PropTypes.shape({
			path: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			graduate: PropTypes.string.isRequired,
			cover: PropTypes.any,
		}).isRequired,
	}).isRequired,
}

export default WorkLink

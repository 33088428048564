import React from 'react'
// import '../utils/constant.less'

import Layout from '../components/layout'
import WorkList from '../components/WorkList'
import Banner from '../components/Banner'

const WorkPage = () => (
	<Layout>
		<WorkList />
		<Banner
			title="Like What You See?"
			subtitle="Meet the grads behind these projects In Real Life at the grad show on April 10 & 11 at Free Space!"
			cta="View Details"
		/>
	</Layout>
)

export default WorkPage
